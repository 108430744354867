import ajax from "../util/ajax";

export const baseUrlByEnvironment = () => {
  switch (process.env.NODE_ENV) {
    case 'production':
      return 'https://api.drivechargers.ru/';
    case 'development':
    default:
      return 'https://api.drvdev.ru/';
  }
};

export const baseUrl = [baseUrlByEnvironment(), 'v1/'].join('');

export const graphqlUrl = [baseUrlByEnvironment(), 'graphql'].join('');

export const getJSON = (path, headers, params) => {
  let url = [baseUrl, path, '?'].join('');
  let i = 0;

  for (let name in params) {
    let value = params[name];
    url += `${name}=${value}`;
    i++;
    if (Object.keys(params).length !== i) {
      url += '&';
    }
  }

  return ajax(url, {
    mode: 'cors',
    cache: 'no-cache',
    crossDomain: true,
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    processData: false,
    referrer: 'no-referrer',
  });
};

export const postJSON = (path, headers, data) => {
  return ajax(baseUrl + path, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    crossDomain: true,
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    processData: false,
    referrer: 'no-referrer',
    body: JSON.stringify(data),
  });
};

export const postFormData = (path, headers, data) => {
  return ajax(baseUrl + path, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    crossDomain: true,
    credentials: 'same-origin',
    headers: {
      ...headers
    },
    processData: false,
    contentType: false,
    mimeType: 'multipart/form-data',
    referrer: 'no-referrer',
    body: data,
  });
};

export const putJSON = (path, headers, data) => {
  return ajax(baseUrl + path, {
    method: 'PUT',
    mode: 'cors',
    cache: 'no-cache',
    crossDomain: true,
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    processData: false,
    referrer: 'no-referrer',
    body: JSON.stringify(data),
  });
};

export const deleteJSON = (path, headers) => {
  return ajax(baseUrl + path, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    crossDomain: true,
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    processData: false,
    referrer: 'no-referrer'
  });
};

export const postGraphql = (headers, data) => {
  return ajax(graphqlUrl, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    crossDomain: true,
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    processData: false,
    referrer: 'no-referrer',
    body: JSON.stringify(data),
  });
};

export const getAgreementsContents = (path) => {
  return ajax(baseUrl + 'help' + path, {
    method: 'GET',
    mode: 'cors',
    crossDomain: true,
    credentials: 'same-origin',
    processData: false,
    referrer: 'no-referrer',
  });
};

export const postAuthEmailVerify = (tokenBody) => {
  return ajax(baseUrl + 'auth/email/verify', {
    method: 'POST',
    mode: 'cors',
    crossDomain: true,
    credentials: 'same-origin',
    processData: false,
    referrer: 'no-referrer',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(tokenBody),
  });
};

export const getManagersList = (headers) => {
  return ajax(baseUrl + 'managers', {
    method: 'GET',
    mode: 'cors',
    crossDomain: true,
    credentials: 'same-origin',
    processData: false,
    referrer: 'no-referrer',
    headers,
  });
};

export const getConnectorMeterValues = (station_id, evse_number, headers) => {
  return ajax(baseUrl + 'meter-values/' + `${station_id}/` + `${evse_number}`, {
    method: 'GET',
    mode: 'cors',
    crossDomain: true,
    credentials: 'same-origin',
    processData: false,
    referrer: 'no-referrer',
    headers,
  });
}

export const postResetStation = (station_id, headers) => {
  return ajax(baseUrl + `stations/${station_id}/reset`, {
    method: 'POST',
    mode: 'cors',
    crossDomain: true,
    credentials: 'same-origin',
    processData: false,
    referrer: 'no-referrer',
    headers,
  });
}

export const postLocationPricing = (location_id, data, headers) => {
  return ajax(baseUrl + `locations/${location_id}/pricing`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    crossDomain: true,
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    processData: false,
    referrer: 'no-referrer',
    body: JSON.stringify(data),
  });
};

export const postStartCharging = (id, data, headers) => {
  return ajax(baseUrl + `connectors/${id}/start`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    crossDomain: true,
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    processData: false,
    referrer: 'no-referrer',
    body: JSON.stringify(data),
  });
};

export const postStopCharging = (id, headers) => {
  return ajax(baseUrl + `connectors/${id}/stop`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    crossDomain: true,
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    processData: false,
    referrer: 'no-referrer',
    body: JSON.stringify({}),
  });
};

export const getOcppMessages = (station_id, headers) => {
  return ajax(baseUrl + 'ocpp-messages/' + `${station_id}`, {
    method: 'GET',
    mode: 'cors',
    crossDomain: true,
    credentials: 'same-origin',
    processData: false,
    referrer: 'no-referrer',
    headers,
  });
}

export const getOcppLogs = (station_id, connector_id, headers) => {
  return ajax(baseUrl + 'ocpp-messages/' + `${station_id}` + '/controller' + (connector_id ? `?connector_id=${connector_id}` : ''), {
    method: 'GET',
    mode: 'cors',
    crossDomain: true,
    credentials: 'same-origin',
    processData: false,
    referrer: 'no-referrer',
    headers,
  });
}

export const deleteOcppLogs = (station_id, headers) => {
  return ajax(baseUrl + 'ocpp-messages/' + `${station_id}` + '/controller', {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    crossDomain: true,
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    processData: false,
    referrer: 'no-referrer',
  });
}

export const postStationMessage = (station_id, data, headers) => {
  return ajax(baseUrl + 'stations/' + `${station_id}/message`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    crossDomain: true,
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    processData: false,
    referrer: 'no-referrer',
    body: JSON.stringify(data),
  });
}

export const postStationDescription = (data, headers) => {
  return ajax(baseUrl + 'stations/description', {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    crossDomain: true,
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    processData: false,
    referrer: 'no-referrer',
    body: JSON.stringify(data),
  });
}

export const postAntiSanctionVersion = (data, headers) => {
    return ajax(baseUrl + 'anti-sanction-protection/versions', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        crossDomain: true,
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            ...headers
        },
        processData: false,
        referrer: 'no-referrer',
        body: JSON.stringify(data),
    });
}

export const getAntiSanctionVersions = (headers) => {
    return ajax(baseUrl + 'anti-sanction-protection/versions', {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        crossDomain: true,
        credentials: 'same-origin',
        processData: false,
        referrer: 'no-referrer',
        headers,
    });
}

export const getStatisticsReport = (headers, mode, from, to, locations) => {
  return ajax(baseUrl + `statistics/report?mode=${mode}&locationIds=${locations || ''}&from=${from}&to=${to}`, {
    method: 'GET',
    mode: 'cors',
    crossDomain: true,
    credentials: 'same-origin',
    processData: false,
    referrer: 'no-referrer',
    headers,
  });
}
